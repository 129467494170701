// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCj5MNP7x46dlQSDVMBhCYgdk4l9HXVkj0",
    authDomain: "professionalism-2.firebaseapp.com",
    databaseURL: "https://professionalism-2-default-rtdb.firebaseio.com/",
    projectId: "professionalism-2",
    storageBucket: "professionalism-2.appspot.com",
    messagingSenderId: "1045010268144",
    appId: "1:1045010268144:web:249ed87786c3b39f2ca735"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
