import { Component, Input, OnInit, OnChanges, OnDestroy, Renderer2, HostListener, SimpleChanges, ViewEncapsulation, ElementRef } from '@angular/core';

import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { FirebaseListObservable, FirebaseObjectObservable } from 'angularfire2/database-deprecated';


import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import {AuthService} from '../auth/auth.service';

import { Question } from '../scenario1/question.model';

export class Session {
  constructor(
	  public $key: string,
	  public title: string,
	  public location: string,
	  public isQuestionActive: boolean = false
  ) { }
}

@Component({
  selector: 'app-sessionusers',
  templateUrl: './sessionusers.component.html',
  styleUrls: ['./sessionusers.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SessionusersComponent implements OnInit, OnChanges {
  sessionID: Observable<any[]>;
  isQuestionActive: Observable<any[]>;

  session_id: string;

  isModalOpen: string;
  modalAnsValue: string;
  modalAnsLetter: string;
  modalQuestion: string;
  modalUser: string;

  hasAnswered: string;
  hasMManswer: string;

  final_data: Array<any> = [];
  @Input() public session: Observable<any[]>;
  public userSession: Observable<any[]>;

  questionList: Question[];
  questionObject: Array<any>;

  public userResponseList: object;

  questionsAnswered: Array<any> = [];

  responseRef: AngularFireList<any>;
  @Input() public questRef: Observable<any[]>;

  //SETUP PEOPLE 
  mmPeople: Array<any> = [];
  @Input() public currentPerson:number;

  //setup response container for MM responses array of answer objects, will iterate through when last answer is submitted.
  mmResponses: Array<any> = [];
  mmCurrentAns: string;

  constructor(public db: AngularFireDatabase, private renderer:Renderer2, public router: Router, route: ActivatedRoute, public authService: AuthService, private elRef:ElementRef) {
  	
    if(authService.isUserAnonymousLoggedIn){
      //console.log(authService.currentUserId);
      //console.log("LOGGED IN");
      //console.log(authService.isUserAnonymousLoggedIn);
    }else{
      //console.log(authService.isUserAnonymousLoggedIn);
      //console.log("NOT LOGGED IN");
 
      this.router.navigateByUrl( '/' , { skipLocationChange:false } );
    }

     route.params.forEach((urlParameters) => {

     	this.sessionID = urlParameters['id'];
     	this.session_id = urlParameters['id'];
     	//console.log(this.sessionID);

     });

    this.session = db.list('/sessions', ref=> ref.orderByKey().equalTo(this.session_id)).valueChanges();

    //console.log(this.session);

    //console.log(this.final_data);

    this.isModalOpen = '0';

    this.responseRef = db.list('/responses');

    this.hasAnswered = '0';
    this.hasMManswer = '0';

    this.userResponseList = {
      ansa:false,
      ansb:false,
      ansc:false,
      ansd:false,
      anse:false
    }

    db.list('/responses', ref=> ref.orderByChild('uid').equalTo(this.authService.currentUserId)).valueChanges().subscribe((questionref) => {

      questionref.forEach((item) => {
        //console.log(item['questionid']);
        this.questionsAnswered.push(item['questionid']);
      });

    });

    this.currentPerson = 0;

    this.mmPeople = [
      {
        studentid:1,
        sex:'female',
        basicInfo:[
          'Female, Age 30',
          'B.A. in Accounting from Cornell',
          'J.D. University of Pittsburgh'
        ],
        image:'/assets/img/cassandra-headshot.png',
        bio:'Interested in small business and corporate transactions. Prior to law school worked for HUD reviewing past real estate red-lining practices. Effective witness in a trial HUD brought against a real estate developer. Also had a good experience as a law intern working on a small employment trial.'
      },
      {
        studentid:2,
        sex:'female',
        basicInfo:[
          'Female, Age 32',
          'B.A. Middlebury College',
          'J.D. Georgetown Law'
        ],
        image:'/assets/img/hannah-headshot.png',
        bio:'Single mohter of 3 young children.  Wrote a law review article on European Union Membership Requirements.  Interested in practicing in international transaction and complex transactions.  Fluent in French and German, Worked in Peace Corp in Morocco.  Ambitious, but has expressed concerns about balancing work with family.'
      },
      {
        studentid:3,
        sex:'male',
        basicInfo:[
          'Male, Age 27',
          'B.A. Bucknell',
          'J.D. Penn State Dickinson Law'
        ],
        image:'/assets/img/chad-headshot.png',
        bio:'Played lacrosse at Bucknell and was President of his fraternity.  Is very interested in labor law.  After college worked for two years in the Governor\'s Office serving as a political liaison to the Department of Labor.  Uncle is a local real estate mogul who currently uses another firm. Becoming a skilled litigator. Eventually hopes to get involved in politics.'
      },
      {
        studentid:4,
        sex:'male',
        basicInfo:[
          'Male, Age 35',
          'B.A. in Engineering at Mullenberg College',
          'J.D. University of Miami'
        ],
        image:'/assets/img/juan-headshot.png',
        bio:'Married with 4 children.  Served in military in Iraq before college. Fluent in Spanish. Received scholarships for both college and law school. Debate champion. At Miami won the Moot Court competition defeating the Columbia Law team in the finals. Very interested in becoming a trial lawyer and complex litigation'
      },
      {
        studentid:5,
        sex:'male',
        basicInfo:[
          'Male, Age 26',
          'B.S. Lehigh University',
          'MBA Wharton School at Penn',
          'J.D. Duke Law'
        ],
        image:'/assets/img/michael-headshot.png',
        bio:'Third generation American, fluent in Mandarin. At Lehigh and Penn focused on economics and internal finanace issues and wrote an article comparing Chinese and U.S. real estate tax treatment.  At Duke was inspired by a Constitutional Law professor and interned in Washington D.C. with an appellate practice firm focusing on money laundering. Wants to return with his wife to Pennsylvania and is very interested in a litigation career. Thinks his understanding of finance will be a real asset.'
      },

    ];

    
  }

  ngOnChanges(changes: SimpleChanges){
    //console.log(changes);
  }

  ngOnDestroy(){
    this.renderer.removeClass(document.body, 'session-users');
  }

  ngOnInit() {
     //console.log('SESSION IS HERE',this.sessionID);
     

     this.renderer.addClass(document.body, 'session-users');


     //GETS LIST OF QUESTIONS ON INITIALIZATION.  This list will be used to populate user template.
     this.db.list('questions').snapshotChanges(['child_changed','child_added']).subscribe(item=>{
      this.questionList = [];
      item.forEach(element => {
         var y = element.payload.toJSON();
         //console.log(y);
         y["$key"] = element.key;
         this.questionList.push(y as Question);
         //console.log(this.questionList);
      });

      this.session.subscribe((session) => {

            // do stuff with our data here.
            // ....
            // asign data to our class property in the end
            // so it will be available to our template
          if(session[0].currentQuestion == 's1_q1' || session[0].currentQuestion == 's1_q2' || session[0].currentQuestion == 's2_q1' || session[0].currentQuestion == 's2_q2' || session[0].currentQuestion == 's3_q1' || session[0].currentQuestion == 's3_q2' || session[0].currentQuestion == 's4_q1' || session[0].currentQuestion == 's4_q2'){
        
            let qholder = this.questionList.find( i => i.$key === session[0].currentQuestion );

            this.questionObject = [];
            let respHolder = [];

            let ansProps = Object.keys(qholder.answers);
            //let ansProps = Object.values(qholder.answers);

            for (let ans of ansProps) { 
                respHolder.push(qholder.answers[ans]);
            }

            qholder.answers = respHolder;

            this.questionObject.push(qholder);
           
            //This toggles the question if user hasn't answered it already. 
            if(this.questionsAnswered.includes(session[0].currentQuestion)){
              this.hasAnswered = '1';
            }else{
              this.hasAnswered = '0';
            }
          }

        });
      

      
      
    });

  }

  recordResponse(event,responseValue,question) {
 
    //Display it in template
    this.isModalOpen = '1';
    
    this.modalAnsValue = this.questionObject[0].answers[responseValue];
    this.modalQuestion = question;
    this.modalUser = this.authService.currentUserId;

    if(responseValue == 0){
      this.modalAnsLetter  = 'a';
    }else if(responseValue == 1){
      this.modalAnsLetter  = 'b';
    }else if(responseValue == 2){
      this.modalAnsLetter  = 'c';
    }else if(responseValue == 3){
      this.modalAnsLetter  = 'd';
    }else if(responseValue == 4){
      this.modalAnsLetter  = 'e';
    }else if(responseValue == 5){
      this.modalAnsLetter  = 'f';
    }else if(responseValue == 6){
      this.modalAnsLetter  = 'g';
    }else if(responseValue == 7){
      this.modalAnsLetter  = 'h';
    }

  }

  submitAns(userid, modalans, questionid, sessionid){

    this.questionsAnswered.push(questionid);

    //Check if they've already submitted an answer. 

    //push answer to firebase.
    this.responseRef.push({ 
          session: sessionid,
          uid: userid,
          questionid: questionid,
          answer: modalans
        });

    //Close Modal.
    this.isModalOpen = '0';
    this.hasAnswered = '1';
  }

  closeModal(){
    this.isModalOpen = '0';
  }

  setAns(answer,event:any){
    this.mmCurrentAns = answer;



    var els = document.querySelectorAll('.ansopt.active');
    //Strip active class
    for (var i = 0; i < els.length; i++) {
      els[i].classList.remove('active');
    }
    
    this.renderer.addClass(event.target,"active");
    //console.log(this.elRef.nativeElement.querySelector('.ansopt'));
  }

  mmAns(currentPerson,userid,sessid,mentor){
    var els = document.querySelectorAll('.ansopt.active');
    //Strip active class
    for (var i = 0; i < els.length; i++) {
      els[i].classList.remove('active');
    }

    this.userResponseList["ans"+mentor] = true;

    console.log(this.userResponseList);

    if(currentPerson < 4){

      var y = {
        student:currentPerson,
        answer:mentor
      };

      this.mmResponses.push(y);

      //console.log(currentPerson);
      this.currentPerson++;
      //console.log(currentPerson);

    }else{

      var y = {
        student:currentPerson,
        answer:mentor
      };

      this.mmResponses.push(y);

      this.hasMManswer = '1';

      var ansObj = {
        questionid:'mm',
        session:sessid,
        uid:userid,
        answer:this.mmResponses
      }

      this.responseRef.push(ansObj);

      //console.log(ansObj);

    }
  }


  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
      this.PostCall();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {
      const userSessRef = this.db.list('/users');

      this.db.list('/users', ref=> ref.orderByChild('uid').equalTo(this.authService.userInfo.user.uid)).snapshotChanges().subscribe(queriedItems => {
        //console.log(queriedItems);
        userSessRef.remove(queriedItems[0].key);
      });
      //console.log(this.userSession);
      return false;
  }

  PostCall() {
      //this.userSession = db.list('/users', ref=> ref.orderByValue('uid').equalTo(this.authService.userInfo.user.uid)).valueChanges();
      //console.log('PostCall');
  }
}
